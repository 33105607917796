<template>
  <div class="root">
    <div class="serviceCenterPc___2Zxws">
      <div class="top___1Og-k">
        <div class="serviceCenterPcTitle___dUfF8">
          <img
            src="../img/logo-i.png"
            alt=""
            style="width: 60px"
            class="logo"
          />
          <span class="logo1">聚信人和</span>
          <div class="circle___fwqzy"></div>
          <div class="title___3INY4">隐私政策</div>
        </div>
      </div>
      <div class="bottom___1NSEv">
        <div class="serviceCenterPcContent___ZpedD">
          <div class="rulesDetail___3_rBE">
            <div class="rulesDetailBox___19ABP">
              <div class="mainContainer___3S5zd">
                <div id="trainDetail" class="content___385ZG">
                  <p style="text-align: center" align:center>
                    <strong>隐私政策</strong>
                  </p>
                  <p>欢迎您使用聚信人和的服务！</p>
                  <p>
                    为使用聚信人和的服务，您应当阅读并遵守《聚信人和使用协议》和《聚信人和隐私政策》。本协议是用户与聚信人和之间的法律协议，是用户注册聚信人和平台账号和/或使用聚信人和服务时使用的通用条款。
                  </p>
                  <p style="text-align: left">
                    <strong
                    >【审慎阅读】请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款。限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。如您对协议有任何疑问，可向聚信人和平台客服咨询。如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容。</strong
                    >
                  </p>
                  <p style="text-align: left">
                    <strong>一、定义</strong>
                  </p>
                  <p>
                    <span
                    >用户，包含注册用户和非注册用户，以下亦称为“您”。注册用户是指通过聚信人和平台完成全部注册程序后，使用聚信人和平台服务或聚信人和网站资料的用户。非注册用户是指未进行注册、直接登录聚信人和平台或通过其他网站进入聚信人和平台直接或间接地使用聚信人和平台服务或聚信人和网站资料的用户。</span
                    >
                  </p>
                  <p style="text-align: left">
                    通常，我们会基于本隐私政策提示的功能收集您的个人信息。某些情况下，如果涉及其他信息的收集我们会单独向您出示个人信息保护说明条款。在处理您的个人信息方面，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则。
                  </p>
                  <p style="text-align: left">
                    <strong>个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策涉及到的个人敏感信息我们将加粗并加下划线的方式提示您注意阅读。在您向聚信人和提供任何属于敏感信息的个人信息前，请您清楚考虑该等提供是恰当的并且同意您的个人敏感信息可按本隐私政策所述的目的和方式进行处理。我们会在得到您的同意后收集和使用您的敏感信息以实现与聚信人和业务相关的功能，并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。</strong>
                  </p>
                  <p style="text-align: left">
                    <strong>二、协议的效力</strong>
                  </p>
                  <p>
                    <strong
                    >1、本协议内容包括本协议正文及《聚信人和隐私政策》，且您在使用聚信人和某一特定服务时，该服务可能会另有单独的协议、相关业务规则（您在使用具体服务时还请详细查看相应的规则）等（以下统称为“规则”）。除另行明确声明外，任何聚信人和及其关联公司提供的服务（以下称为“聚信人和平台服务”）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用聚信人和平台服务；如您继续访问和使用聚信人和平台服务，即视为您已确知并完全同意本协议各项内容。</strong
                    >
                  </p>

                  <p>
                    2、聚信人和有权根据国家法律法规的更新、产品和服务规则的调整需要不时地制订、修改本协议及/或各类规则，并提前以网站公示的方式进行公示。<strong
                  >您可在聚信人和网站首页下方的
                    “协议规则专区”查看并下载最新版本的协议规则</strong
                  >信息
                  </p>
                  <p style="text-align: left">
                    <strong>三、适用范围</strong>
                  </p>
                  <p>
                    <strong
                    >1、在您使用我们网络服务，或访问我们平台网页时，我们自动接收并记录的您的浏览器和计算机上及其他移动终端设备的信息，包括但不限于您的IP地址、地址位置信息、浏览器的类型、使用的语言、访问日期和时间、通讯账户、通讯时长、软硬件特征信息及您需求的网页记录等数据；</strong
                    >
                  </p>
                  <p>
                    <strong
                    >2、您在注册账户时填写、提供或上传的姓名、手机号，我们亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息</strong
                    >
                  </p>
                  <p style="text-align: left">
                    <strong>
                      四、信息使用
                    </strong>
                  </p>
                  <p>
                    <strong>1、我们不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，</strong>
                  </p>
                  <p><strong>2、我们亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何我们平台用户如从事上述活动，一经发现，我司有权立即终止与该用户的服务协议。</strong></p>
                  <p>3、我们会遵守法律法规的规定，将您的个人信息存储在安全的服务器设备或其他安全系统更高的数据库或设备中，我们会为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。</p>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
        >冀ICP备20010751号</a
        ><span>|</span
      ><a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        rel="nofollow"
      >冀ICP备20010751号-2</a
      ><span>|</span
      ><a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        rel="nofollow"
      >津文执网文【2019】</a
      ><span>|</span
      ><a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        rel="nofollow"
      >河北坤洲网络科技有限公司</a
      ><span>|</span
      ><a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
      >联系我们</a
      >
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.serviceCenterPc___2Zxws {
  min-height: 100vh;
  background-color: #f6f6f6;
}

div {
  box-sizing: border-box;
}

div {
  display: block;
}

.logo1 {
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -45px;
  margin-left: 20px;
  color: rgba(177, 171, 171, 0.966);
}

.serviceCenterPc___2Zxws .top___1Og-k {
  width: 100%;
  height: 112px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logo {
  display: block;
  margin-left: -50px;
  margin-top: 20px;
}

.serviceCenterPc___2Zxws
.top___1Og-k
.serviceCenterPcTitle___dUfF8
.circle___fwqzy {
  width: 6px;
  height: 6px;
  background: #595757;
  border-radius: 50%;
  margin: 0 120px;
  margin-top: -15px;
}

.title___3INY4 {
  font-size: 34px;
  line-height: 40px;
  color: #595757;
  margin-top: -25px;
  margin-left: 150px;
}

.serviceCenterPc___2Zxws .bottom___1NSEv {
  width: 100%;
  height: calc(100% - 112px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.serviceCenterPc___2Zxws .bottom___1NSEv .serviceCenterPcContent___ZpedD {
  width: 1190px;
  height: 100%;
  margin-top: 24px;
  margin-bottom: 64px;
}

.rulesDetail___3_rBE {
  width: 100%;
}

.rulesDetail___3_rBE .rulesDetailBox___19ABP {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rulesDetail___3_rBE .rulesDetailBox___19ABP .mainContainer___3S5zd {
  width: 880px;
  height: 100%;
  background-color: #fff;
  padding: 32px 32px 0;
}

.rulesDetail___3_rBE
.rulesDetailBox___19ABP
.mainContainer___3S5zd
.content___385ZG {
  width: 100%;
  min-height: 380px;
  padding: 16px 24px;
  background: #fbfbfb;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  line-height: 30px;
  word-break: break-all;
  word-wrap: break-word;
}

.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}

.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}

.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>
